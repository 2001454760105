<template>
    <client-page>
        
        <sub-visual sh="해외사업" tabActive="해외아동결연" bg="/images/sub/visual/sv2.jpg" />

        <section class="section">
            <v-container>

                <business-item tit="1:1 결연아동" sc="빈민지역 아동을 대상으로 결연아동을 선정, 1:1로 후원자와 매칭해 아동 생계에 필요한 전반적인 지원을 합니다." file="oo">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            기초생계지원 : 의식주에 필요한 기초 생필품 및 식료품 지원
                        </li>
                        <li class="dot-text">
                            교육지원 : 아동이 꿈을 포기하지 않고 지속적으로 공부할 수 있도록 학비·교과서·학용품 등 지원
                        </li>
                        <li class="dot-text">
                            보건의료지원 : 예방접종·영양제 등으로 아동의 기초영양과 보건을 챙기고, 아동이 다치거나 질병 발생 시 긴급 의료지원 실시
                        </li>
                    </ul>
                </business-item> 

                <business-item tit="특수결연아동" sc="즉각적이고 더 많은 도움이 필요한 화상아동, 미혼모와 아이를 대상으로 일대다(또는 1:1) 결연을 진행할 수 있습니다." file="alliance">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            화상아동 결연 지원 : 지속적으로 발라줘야 하는 화상약품 지원, 치료비로 생계가 어려운 가정에 생필품 및 식료품 지원
                        </li>
                        <li class="dot-text">
                            미혼모 결연 지원 : 10대 미혼모들이 학업을 이어갈 수 있도록 학비·교과서·학용품 등 지원, 어린 아이들을 키우는데 필요한 생필품(기저귀, 분유 등) 지원
                        </li>
                    </ul>
                </business-item> 

            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from '@/components/client/sub/sub-visual.vue';
import BusinessItem from '@/components/client/sub/business-item.vue';
export default {
    components: {
        ClientPage,
        SubVisual,
        BusinessItem,
    },
};
</script>