var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "해외사업",
      "tabActive": "해외아동결연",
      "bg": "/images/sub/visual/sv2.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('business-item', {
    attrs: {
      "tit": "1:1 결연아동",
      "sc": "빈민지역 아동을 대상으로 결연아동을 선정, 1:1로 후원자와 매칭해 아동 생계에 필요한 전반적인 지원을 합니다.",
      "file": "oo"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 기초생계지원 : 의식주에 필요한 기초 생필품 및 식료품 지원 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 교육지원 : 아동이 꿈을 포기하지 않고 지속적으로 공부할 수 있도록 학비·교과서·학용품 등 지원 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 보건의료지원 : 예방접종·영양제 등으로 아동의 기초영양과 보건을 챙기고, 아동이 다치거나 질병 발생 시 긴급 의료지원 실시 ")])])]), _c('business-item', {
    attrs: {
      "tit": "특수결연아동",
      "sc": "즉각적이고 더 많은 도움이 필요한 화상아동, 미혼모와 아이를 대상으로 일대다(또는 1:1) 결연을 진행할 수 있습니다.",
      "file": "alliance"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 화상아동 결연 지원 : 지속적으로 발라줘야 하는 화상약품 지원, 치료비로 생계가 어려운 가정에 생필품 및 식료품 지원 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 미혼모 결연 지원 : 10대 미혼모들이 학업을 이어갈 수 있도록 학비·교과서·학용품 등 지원, 어린 아이들을 키우는데 필요한 생필품(기저귀, 분유 등) 지원 ")])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }